import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { graphql } from 'gatsby'
import { SEO } from '../components'
import { LocaleContext } from '../components/layout/layout'
import LocalizedLink from '../components/LocalizedLink'
import { H1, P } from '../styles/common/Typo'
import { Container, Section } from '../styles/common/Layout'
import styled from 'styled-components'
import { CategoryWrapper, Tag, TagWrapper } from '../styles/common/Elements'

const List = styled.div`
  margin-top: 3rem;
  a {
    display: block;
    font-size: 1.25rem;
    margin: 1rem 0;
  }
`

const LocaleSwitcherStyle = theme => css`
  [data-name='locale-switcher'] {
    color: #fff;
    a {
      color: white;
    }
  }
`

const Categories = ({ location, data: { categories }, pageContext: { locale } }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  return (
    <>
      <SEO title={`${i18n.allCategories} | ${i18n.defaultTitleAlt}`} pathname={location.pathname} locale={locale} />
      <Section id="section__categories_overview" SmallPadding>
        <Container>
          <H1>{i18n.allCategories}</H1>
          <P>{categories.totalCount} {i18n.entries}</P>
          <CategoryWrapper>
            {categories.edges.map(cat => (
              <Tag NoFirstMargin key={cat.node.data.name}>
                <LocalizedLink to={`/categories/${kebabCase(cat.node.data.name)}`}>
                  {cat.node.data.name}
                </LocalizedLink>
              </Tag>
            ))}
          </CategoryWrapper>
        </Container>
      </Section>
    </>
  )
}
export default Categories

Categories.propTypes = {
  data: PropTypes.shape({
    categories: PropTypes.shape({
      edges: PropTypes.array.isRequired,
      totalCount: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}
export const pageQuery = graphql`
  query CategoriesOverview($locale: String!) {
    categories: allPrismicCategory(filter: { lang: { eq: $locale } }, sort: { fields: [data___name], order: ASC }) {
      totalCount
      edges {
        node {
          data {
            name
          }
        }
      }
    }
  }
`